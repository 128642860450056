const setConfig = (env:string) => {

    const local = {

        Environment: 'local development',
        Host: 'http://localhost:3000',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_jovMGtVgn',
            userPoolWebClientId: '268r4a4kbuelqe4ojjs9tf71mu',

            API: {
                endpoints: [
                    {
                        name: 'llm-API-Gateway-dev',
                        //endpoint: "https://dev.metricaition.com"
                        endpoint: 'https://cvgmitcdg8.execute-api.eu-west-2.amazonaws.com',
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'llm-API-Gateway-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                    //"x-api-key":                        "alZyP6wcWB6u1gmCJ6aqi4lrqMOydBhF9ot3oM5N",
                     Authorization: ""
                }
            }
        }
    }

    const dev = {

        Environment: 'development',
        Host: 'https://dev.metricaition.com',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_jovMGtVgn',
            userPoolWebClientId: '268r4a4kbuelqe4ojjs9tf71mu',

            API: {
                endpoints: [
                    {
                        name: 'llm-API-Gateway-dev',
                        //endpoint: "https://dev.metricaition.com"
                        endpoint: ""
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'llm-API-Gateway-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                    //"x-api-key":                        "alZyP6wcWB6u1gmCJ6aqi4lrqMOydBhF9ot3oM5N",
                     Authorization: ""
                }
            }
        }
    }

    const stage = { 
 
        Environment: 'staging',
        Host: 'https://stage.metricaition.com',

        Auth: {
            region: 'eu-west-2',
            userPoolId: 'eu-west-2_mZD2LGuwG',
            userPoolWebClientId: '577vrl63vmknemj5c5uqeusit0',
            
            API: {
                endpoints: [    
                    {
                        name: 'metricaition-API-Gateway-stage',
                        endpoint: ""
                    }
                ]
            }
        },
        ApiGateway: { 

            apiName: 'metricaition-API-Gateway-stage',
            apiPath: '/api/', // the (stage) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                     Authorization: ""
                }
            }
        }
    }

    const prod = { 

        Environment: '', // always keep blank
        Host: 'https://metricaition.com',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_mq3A2qt3n',
            userPoolWebClientId: '3fbirvq9hcoaq3qe5nuhdk3v2p',
           
            API: {
                endpoints: [
                    {
                        name: 'openai-API-Gateway',
                        //endpoint: "https://metricaition.com",
                         endpoint: ""
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'openai-API-Gateway',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                    //"x-api-key":                        "uKUHgDQ6r48kseGcGxvDh2ybIEAU3ulxaLb6emp9",
                    Authorization: ""
                }
            }
        }
    };



      switch (env) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
            return (dev);

        case 'local':
        default:
            return (local);
    }
}


const env =  process.env.REACT_APP_STAGE === undefined ? "local" : process.env.REACT_APP_STAGE.trim();



console.log('ORIGIN', window.location.origin);

const config = setConfig(env)


if (config.Auth.API.endpoints[0].endpoint.length === 0) {               // www version of domain trapped here
    config.Auth.API.endpoints[0].endpoint = window.location.origin; 
}


const awsconfig = config.Auth;
const environment = `${config.Environment} 1v0`;
const apiGateway = config.ApiGateway;
const logginglevel = 'VERBOSE';         // either: ERROR WARN INFO DEBUG VERBOSE
const host = config.Host;
const siteTitle = "MetricAItion's Website Analysis using AI Large Language Model (LLM)"
const isProd = (env === "prod");

//console.debug('config', JSON.stringify(config, null, 2));

export { awsconfig, environment, apiGateway, logginglevel, host, env, isProd, siteTitle };
