
import {MenuOption, MenuButtonOption, RoutingValue} from './types'
import {ContentFsm} from './const'
import {env, isProd} from './config'

import {Home} from './Home'
import {HomeAuth} from './HomeAuth'
import {SignIn} from './SignIn'
import {SignOut} from './SignOut'
import {Catchall} from './Catchall'

import {Order} from './Order'
import {OrderSuccess} from './OrderSuccess'
import {OrderCancel} from './OrderCancel'
import {About} from './About'
import {Contact} from './Contact'
import {Terms} from './Terms'
import {Support} from './Support'
import {Privacy} from './Privacy'
import {Maintenance} from './Maintenance'
import {OrderFail} from './OrderFail'
import {Faq} from './Faq'
import {Pages} from './Pages'



// This module is all about the menu options available for authenticated and unauthenticted users
// See Layout.tsx for how it is presented
     
// MENUS



//const defaultPriceCodes:defaultPriceCodes[] = []; 

const privateSite = ((env === 'dev') || (env === 'stage'));   // used to block public access to stage and dev pages


//priceCode({type: 'Landing', live: false, charge: true});


export const footerOptions :MenuOption[] = [
                                      {path: '/about', title: 'About Us'},
                                      {path: '/contact', title: 'Contact'},
                                      {path: '/faq', title: 'FAQs'},
                                      {path: '/terms', title: 'Terms & Conditions'},
                                      {path: '/support', title: 'Support'},
                                      {path: '/privacy', title: 'Privacy & Cookies'}
                                    ]

export const unauthOptions :MenuOption[] = (privateSite) ? [] : [
                                      {path: '/home', title: 'Home'},
                                      {path: '/order', title: 'Order a Report'},
                                    ]    


export const   authOptions :MenuOption[] = [
                                      {path: '/home', title: 'Home'},
                                      {path: '/test', title: 'TEST a Landing Page'},
                                      {path: '/order', title: 'Order a Report'},
                                      {path: '/freeorder', title: 'Order a Report NO CHARGE'}
                                    ]    

export const   menuButtonCancel          :MenuButtonOption  = {path: '/cancel',  title: 'Cancel',  variant: 'warning', state: ContentFsm.REVALIDATE}
export const   menuButtonSignin          :MenuButtonOption  = {path: '/signin',  title: 'Signin',  variant: 'primary', state: ContentFsm.SIGNING_IN}        
export const   menuButtonSignout         :MenuButtonOption  = {path: '/signout', title: 'Signout', variant: 'secondary', state: ContentFsm.SIGNING_OUT}         
 

// PERMITTED ROUTES (revalidate os limbo land between auth and unauth)

export const   revalidateRoutes     :RoutingValue[] = [
                                            {path:'*',          component: Catchall                             }
                                          ]

export const   unauthRoutes         :RoutingValue[] = (privateSite) ? [
                                              {path: '/signin',        component: SignIn                                  }, 
                                              {path: '/order-success', component: Pages                              },             
                                              {path: '/order-fail',    component: Pages                              },    
                                              {path: '/order-cancel',  component: Pages                              },                                  
                                              {path: '*',              component: Pages,   props:{overidePath: '/private'}}
                                            ]                       : [            
                                            //{path: '/home',     component: Home                                 }, //DELETE
                                            //{path: '/',         component: Home                                 },   
                                            //{path: '',          component: Home                                 },
                                            //{path: '/freeorder',component: Home                                 },
                                            //{path: '/test',     component: Home                                 },
                                            {path: '/home',     component: Pages,    props:{overidePath: '/home'}},     
                                            {path: '/',         component: Pages,    props:{overidePath: '/home'}},     
                                            {path: '',          component: Pages,    props:{overidePath: '/home'}},  

                                            {path: '/freeorder',component: Pages,    props:{overidePath: '/home'}},  // traps erroneous call   
                                            {path: '/test',     component: Pages,    props:{overidePath: '/home'}},  // traps erroneous call                         
                                            
                                            {path: '/order',    component: Order,    props:{api: 'stripe/session/create/', auth: false, skew: {type: 'Landing', live: isProd, charge: true} }},     
                                            
                                            {path: '/signin',   component: SignIn                               },
                                            
                                            {path: '*',         component: Pages                                } // catches all remainig html pages
                                          ]

export const   authRoutes          :RoutingValue[] = [
                                            {path: '/home',     component: Pages,    props:{overidePath: '/home'}},     
                                            {path: '/',         component: Pages,    props:{overidePath: '/home'}},
                                            {path: '',          component: Pages,    props:{overidePath: '/home'}},
                                            
                                            {path: '/freeorder',component: Order,    props:{api: 'auth/stripe/session/create', auth: true, skew: {type: 'Landing', live: isProd, charge: false} }}, // no payment on this version - zero piced
                                            {path: '/test',     component: Order,    props:{api: 'auth/test',                  auth: true, skew: {type: 'Landing', live: isProd, charge: false} }}, // no payment on this version
                                          
                                            {path: '/order',    component: Order,    props:{api: 'stripe/session/create/',     auth: false, skew: {type: 'Landing', live: isProd, charge: true} }},  
                                            
                                            {path: '/signout',  component: SignOut                              },
                                            
                                            {path: '/signin',   component: Pages,    props:{overidePath: '/home'}}, // traps erroneous call
                                            
                                            {path: '*',         component: Pages                                 }  // catches all remainig html pages
                                          ]

                                    